import applyMiddleware from '../middlewares';
import withDevtools from '../enhancers/devtools';
import defaults from '../defaults';

function createStoreState(key, initialState) {
  var _listeners2 = [];
  var currentState = initialState;
  var storeState = {
    key: key,
    getState: function getState() {
      return currentState;
    },
    setState: function setState(nextState) {
      currentState = nextState;

      for (var _i = 0, _listeners = _listeners2; _i < _listeners.length; _i++) {
        var listener = _listeners[_i];
        listener(currentState);
      }
    },
    resetState: function resetState() {
      storeState.setState(initialState);
    },
    subscribe: function subscribe(listener) {
      _listeners2 = _listeners2.concat(listener);
      return function unsubscribe() {
        _listeners2 = _listeners2.filter(function (fn) {
          return fn !== listener;
        });
      };
    },
    listeners: function listeners() {
      return _listeners2;
    }
  };
  storeState.mutator = applyMiddleware(storeState, defaults.middlewares);
  return storeState;
}

export default withDevtools(createStoreState);