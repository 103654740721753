var updateMiddleware = function updateMiddleware(storeState) {
  return function (next) {
    return function (arg) {
      var output;
      var state = storeState.getState();
      var nextState = next(state, arg, function (out) {
        output = out;
      });

      if (nextState !== state) {
        storeState.setState(nextState);
      }

      return output;
    };
  };
};

export default updateMiddleware;