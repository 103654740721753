import hash from '../utils/hash';

function createKey(initialState, actions, name) {
  var src = !name ? Object.keys(actions).reduce(function (acc, k) {
    return acc + actions[k].toString();
  }, '') : '';
  return [name, hash(src + JSON.stringify(initialState))].filter(Boolean);
}

export function createStore(_ref) {
  var _ref$name = _ref.name,
      name = _ref$name === void 0 ? '' : _ref$name,
      initialState = _ref.initialState,
      actions = _ref.actions;
  var key;
  return {
    get key() {
      // lazy evaluate key on first access
      return key || (key = createKey(initialState, actions, name));
    },

    initialState: initialState,
    actions: actions
  };
}